import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'



const Navbar = props => {
  const [active, setActive] = useState(false);
  const [navBarActiveClass, setNavBarActiveClass] = useState('');

  useEffect(() => {
    if (active) {
      setNavBarActiveClass('is-active');
    } else {
      setNavBarActiveClass('');
    }
  }, [active]);

  const toggleHamburger = () => setActive(!active);

  return (
    <nav
      className="navbar is-transparent is-fixed-top"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logo} alt="Kaldi" style={{ height: 'auto', maxHeight: 'none', width: '80px', margin: '10px' }} />
          </Link>
          {/* Hamburger menu */}
          <div
            role="presentation"
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${navBarActiveClass}`}
        >
          <div className="navbar-start has-text-centered">
          </div>
          <div className="navbar-end has-text-centered">
            <Link className="navbar-item" to="/about">
              About
          </Link>
            <Link className="navbar-item" to="/articles">
              Articles
          </Link>
            <Link className="navbar-item" to="/topics">
              Topics
          </Link>
            <Link className="navbar-item" to="/resources">
              Resources
          </Link>
            <Link className="navbar-item" to="/contact">
              Contact
          </Link>
            <Link className="navbar-item" to="/donate">
              <span style={{ backgroundColor: '#73d64f', padding: '4px 20px', borderRadius: '22px', color: 'white' }}>Donate</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
